<template>
  <div class="register-box">
    <div class="register-left">
      <div class="register-title">
        <div class="register-logo">
          <img class="logo-img" src="../static/img/register-logo.png" />
          <div class="logo-name">{{ $t("registerPage.companyName") }}</div>
        </div>
        <div class="logo-title">{{ $t("loginPage.title") }}</div>
      </div>
      <img class="register-bg" src="../static/img/register-bg.png" />
    </div>
    <div class="register-right">
      <div class="login-text">
        <span class="hover" @click="langeSwitch">
          {{
          lange ? "ENG" : "中"
          }}
        </span>
      </div>
      <div class="right-in">
        <el-tabs class="tab-box" :tab-position="tabPosition" v-model="role" @tab-click="tabsClick">
          <el-tab-pane :label="$t('loginPage.supplierRegister')" name="1"></el-tab-pane>
          <el-tab-pane :label="$t('loginPage.investorRegister')" name="2"></el-tab-pane>
          <el-tab-pane :label="$t('loginPage.enterpriseRegister')" name="3"></el-tab-pane>
        </el-tabs>
        <div v-show="role === '1'" class="supplier-box">
          <el-form
            :label-position="labelPosition"
            ref="registerForm"
            :model="registerForm"
            :rules="registerRules"
            label-width="160px"
          >
            <el-form-item :label="$t('registerPage.fullNameOfEnterprise')" prop="username">
              <el-input
                v-model="registerForm.username"
                maxlength="50"
                type="text"
                ref="registerForm.username"
                :placeholder="$t('registerPage.fullNamePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerPage.password')" prop="password">
              <el-input
                v-model="registerForm.password"
                minlength="8"
                maxlength="16"
                type="password"
                ref="registerForm.password"
                :placeholder="$t('registerPage.passwordFormat')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerPage.passwordConfirm')" prop="passwordConfirm">
              <el-input
                v-model="registerForm.passwordConfirm"
                minlength="8"
                maxlength="16"
                type="password"
                ref="registerForm.passwordConfirm"
                :placeholder="$t('registerPage.passwordConfirmPlaceholder')"
              ></el-input>
            </el-form-item>
            <div class="email-box">
              <el-form-item :label="$t('registerPage.email')" prop="email">
                <el-input
                  class="email-input"
                  v-model="registerForm.email"
                  type="text"
                  ref="registerForm.email"
                  :placeholder="$t('registerPage.emailPlaceholder')"
                ></el-input>
              </el-form-item>
              <el-form-item class="code-box">
                <el-button
                  :class="isClickCodeBtn ? 'btn-green' : 'btn-gray'"
                  class="code-btn"
                  type="primary"
                  @click="getCode"
                  :loading="loadingCode"
                  :disabled="!isClickCodeBtn"
                >{{codeText}}</el-button>
              </el-form-item>
            </div>
            <el-form-item :label="$t('registerPage.emailCode')" prop="emailCode">
              <el-input
                v-model="registerForm.emailCode"
                type="text"
                ref="registerForm.emailCode"
                :placeholder="$t('registerPage.emailCodePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item class="agreement-box" style="display: none">
              <el-radio
                v-model="registerForm.isCheck"
                label="1"
              >{{$t("registerPage.radioTextBlack")}}</el-radio>
              <span class="agreement-text">{{$t("registerPage.agreementOne")}}</span>
              <span class="agreement-text">{{$t("registerPage.agreementTwo")}}</span>
            </el-form-item>
            <el-form-item class="regBtn-box">
              <el-button
                class="btn-black"
                type="primary"
                :loading="loadingRegister"
                @click="register"
              >{{$t("registerPage.registerBtn")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="role === '2'" class="supplier-box">
          <el-form
            :label-position="labelPosition"
            ref="registerForm"
            :model="registerForm"
            :rules="registerRules"
            label-width="160px"
          >
            <el-form-item :label="$t('registerPage.investorName')" prop="investorName">
              <el-input
                v-model="registerForm.investorName"
                type="text"
                ref="registerForm.investorName"
                :placeholder="$t('registerPage.investorNamePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerPage.password')" prop="password">
              <el-input
                v-model="registerForm.password"
                type="password"
                ref="registerForm.password"
                :placeholder="$t('registerPage.passwordFormat')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerPage.passwordConfirm')" prop="passwordConfirm">
              <el-input
                v-model="registerForm.passwordConfirm"
                type="password"
                ref="registerForm.passwordConfirm"
                :placeholder="$t('registerPage.passwordConfirmPlaceholder')"
              ></el-input>
            </el-form-item>
            <div class="email-box">
              <el-form-item :label="$t('registerPage.email')" prop="email">
                <el-input
                  class="email-input"
                  v-model="registerForm.email"
                  type="text"
                  ref="registerForm.email"
                  :placeholder="$t('registerPage.emailPlaceholder')"
                ></el-input>
              </el-form-item>
              <el-form-item class="code-box">
                <el-button
                  :class="isClickCodeBtn ? 'btn-green' : 'btn-gray'"
                  class="code-btn"
                  type="primary"
                  @click="getCode"
                  :loading="loadingCode"
                  :disabled="!isClickCodeBtn"
                >{{codeText}}</el-button>
              </el-form-item>
            </div>
            <el-form-item :label="$t('registerPage.emailCode')" prop="emailCode">
              <el-input
                v-model="registerForm.emailCode"
                type="text"
                ref="registerForm.emailCode"
                :placeholder="$t('registerPage.emailCodePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item class="agreement-box" style="display: none">
              <el-radio
                v-model="registerForm.isCheck"
                label="1"
              >{{$t("registerPage.radioTextBlack")}}</el-radio>
              <span class="agreement-text">{{$t("registerPage.agreementOne")}}</span>
              <span class="agreement-text">{{$t("registerPage.agreementTwo")}}</span>
            </el-form-item>
            <el-form-item class="regBtn-box">
              <el-button
                class="btn-black"
                type="primary"
                @click="register"
              >{{$t("registerPage.registerBtn")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="role === '3'" class="supplier-box">
          <el-form
            :label-position="labelPosition"
            ref="registerForm"
            :model="registerForm"
            :rules="registerRules"
            label-width="160px"
          >
            <el-form-item :label="$t('registerPage.fullNameOfEnterprise')" prop="username">
              <el-input
                v-model="registerForm.username"
                type="text"
                ref="registerForm.username"
                :placeholder="$t('registerPage.fullNamePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerPage.password')" prop="password">
              <el-input
                v-model="registerForm.password"
                type="password"
                ref="registerForm.password"
                :placeholder="$t('registerPage.passwordFormat')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerPage.passwordConfirm')" prop="passwordConfirm">
              <el-input
                v-model="registerForm.passwordConfirm"
                type="password"
                ref="registerForm.passwordConfirm"
                :placeholder="$t('registerPage.passwordConfirmPlaceholder')"
              ></el-input>
            </el-form-item>
            <div class="email-box">
              <el-form-item :label="$t('registerPage.email')" prop="email">
                <el-input
                  class="email-input"
                  v-model="registerForm.email"
                  type="text"
                  ref="registerForm.email"
                  :placeholder="$t('registerPage.emailPlaceholder')"
                ></el-input>
              </el-form-item>
              <el-form-item class="code-box">
                <el-button
                  :class="isClickCodeBtn ? 'btn-green' : 'btn-gray'"
                  class="code-btn"
                  type="primary"
                  @click="getCode"
                  :loading="loadingCode"
                  :disabled="!isClickCodeBtn"
                >{{codeText}}</el-button>
              </el-form-item>
            </div>
            <el-form-item :label="$t('registerPage.emailCode')" prop="emailCode">
              <el-input
                v-model="registerForm.emailCode"
                type="text"
                ref="registerForm.emailCode"
                :placeholder="$t('registerPage.emailCodePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item class="agreement-box" style="display: none">
              <el-radio
                v-model="registerForm.isCheck"
                label="1"
              >{{$t("registerPage.radioTextBlack")}}</el-radio>
              <span class="agreement-text">{{$t("registerPage.agreementOne")}}</span>
              <span class="agreement-text">{{$t("registerPage.agreementTwo")}}</span>
            </el-form-item>
            <el-form-item class="regBtn-box">
              <el-button
                class="btn-black"
                type="primary"
                @click="register"
              >{{$t("registerPage.registerBtn")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rules from '../plugins/rules';
import Storage from '../utils/storage';
export default {
  data() {
    return {
      // 角色类型 1:供应商，2:投资人，3:核心企业
      role: '1',
      lange: false,
      tabPosition: 'top',
      labelPosition: 'right',
      registerForm: {
        username: '',
        investorName: '',
        password: '',
        passwordConfirm: '',
        email: '',
        emailCode: '',
        isCheck: '1'
      },
      codeText: this.$t('registerPage.codeText'),
      loadingCode: false,
      loadingRegister: false,
      isClickCodeBtn: true,
      //  效验规则
      registerRules: {
        // 供应商、核心企业账号规则
        username: [
          { required: true, trigger: 'blur', validator: rules.validateRegAccount }
        ],
        // 投资人账号规则
        investorName: [
          { required: true, trigger: 'blur', validator: rules.validateInvestorAccount }
        ],
        password: [
          { required: true, trigger: 'blur', validator: rules.validatePass }
        ],
        passwordConfirm: [
          { required: true, trigger: 'blur', validator: rules.validatePass }
        ],
        email: [
          { required: true, trigger: 'blur', validator: rules.validateEmail }
        ],
        emailCode: [
          { required: true, trigger: 'blur', validator: rules.validateEmailCode }
        ]
      },
    };
  },
  mounted() {
    this.lange = Storage.getLocalItem('i18n') === 'zh-en';
    let local = this.lange ? 'zh-en' : 'zh-cn';
    this.$i18n.locale = local;
    Storage.setLocalItem('i18n', local);
  },
  methods: {
    // 获取验证码
    getCode() {
      if (this.codeText === this.$t('registerPage.codeText')) {
        this.loadingCode = true;
        if (this.registerForm.email === '') {
          this.loadingCode = false;
          this.$message.error(this.$t('registerPage.emailErr'));
          return false;
        }
        if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(this.registerForm.email)) {
          this.loadingCode = false;
          this.$message.error(this.$t('registerPage.emailFormat'));
          return false;
        }
        const data = {
          email: this.registerForm.email,
          type: '1'
        };
        this.$axios.get("/v1/email/code", { params: data }).then(result => {
          if (result.code === 0 || result.code === '0') {
            this.loadingCode = false;
            this.isClickCodeBtn = false;
            let second = 60;
            this.codeText = second + '(s)';
            // 定时器倒数
            const timeClock = setInterval(() => {
              second--;
              this.codeText = second + '(s)';
              if (second < 0) {
                // 当倒计时小于0时清除定时器
                clearInterval(timeClock);
                this.codeText = this.$t('registerPage.codeText');
                second = 60;
                this.isClickCodeBtn = true;
              }
            }, 1000);
          } else {
            this.loadingCode = false;
            this.$message.error(result.message);
          }
        }).catch(error => {
          this.loadingCode = false;
          console.log(error);
        });
      } else {
        this.loadingCode = false;
      }
    },
    // 语言切换
    langeSwitch() {
      this.lange = !this.lange;
      let local = this.lange ? 'zh-en' : 'zh-cn';
      Storage.setLocalItem('i18n', local);
      this.$i18n.locale = local;
      this.$store.dispatch('auth/language', local);
      this.$forceUpdate();
      this.codeText = this.$t('registerPage.codeText');
    },
    register() {
      this.loadingRegister = true;
      if (this.role !== '2' && !this.registerForm.username) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.fullNameErr'));
      }
      if (this.role === '2' && !this.registerForm.investorName) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.investorErr'));
      }
      if (!this.registerForm.password) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('loginPage.passwordErr'));
      }
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/gi.test(this.registerForm.password)) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.passwordFormat'));
      }
      if (!this.registerForm.passwordConfirm) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('loginPage.passwordErr'));
      }
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/gi.test(this.registerForm.passwordConfirm)) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.passwordFormat'));
      }
      if (this.registerForm.passwordConfirm !== this.registerForm.password) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.passwordConNotSame'));
      }
      if (!this.registerForm.email) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.emailErr'));
      }
      if (!(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(this.registerForm.email))) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.emailFormat'));
      }
      if (!this.registerForm.emailCode) {
        this.loadingRegister = false;
        return this.$message.error(this.$t('registerPage.emailCodeErr'));
      }
      const params = { ...this.registerForm };
      params.code = params.emailCode;
      params.role = this.role;
      if (this.role === '2') {
        params.username = this.registerForm.investorName;
      } else {
        params.username = this.registerForm.username;
      }

      delete params.emailCode;
      delete params.investorName;
      delete params.passwordConfirm;
      delete params.isCheck;
      const data = {
        ...params
      };
      this.$axios.post('/v1/register/user', { ...data }).then(result => {
        if (result.code === 0 || result.code === "0") {
          this.loadingRegister = false;
          const registerData = { ...result.data };
          registerData.type = registerData.role === 1 ? 'LOAN' : registerData.role === 2 ? 'INVEST' : 'CORE';
          this.$store.dispatch('auth/loginSuccess', registerData);
          let routerLink = "/";
          switch (registerData.role) {
            case 1:
              routerLink = registerData.approved === 9 ? "/invitation-code" : "/company-create";
              break;
            case 2:
              routerLink = '/invest-create';
              break;
            case 3:
              routerLink = '/enterprise-create';
              break;
          }
          this.$router.replace({ path: routerLink });
        } else {
          this.loadingRegister = false;
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(err => {
        this.loadingRegister = false;
      });
    },
    tabsClick() {
      // this.$refs['registerForm'].resetFields();
    }
  },
};
</script>
<style lang="scss" scoped>
.register-box {
  // min-width: 1000px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ::v-deep .el-input {
    display: inline-block;
    input {
      width: 520px;
      background: transparent;
      -webkit-appearance: none;
      padding: 12px 5px 12px 15px;
      border: 1px solid #dddfe6;
      border-radius: 6px !important;
    }
  }
}
.register-left {
  position: relative;
  width: 40%;
  height: 100%;
  background: linear-gradient(136deg, #a4f4f0 0%, #58dcd2 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  .register-title {
    width: auto;
    margin-left: 79px;
    display: flex;
    flex-direction: column;
    // margin-top: -400px;
  }
  .register-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo-img {
      width: 47px;
      height: 37px;
    }
    .logo-name {
      font-size: 24px;
      font-weight: normal;
      color: #000000;
      margin-left: 12px;
      font-family: "fontfmb";
    }
  }
  .logo-title {
    font-size: 17px;
    color: #000000;
    line-height: 20px;
    margin-top: 27px;
    font-family: "fontfmb";
  }
  .register-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 487px;
    // height: 348px;
  }
}
.register-right {
  width: 60%;
  position: relative;
  // padding: 0 90px;
  // margin-left: -20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-text {
    position: absolute;
    top: 32px;
    right: 37px;
    font-size: 18px;
    font-weight: bold;
    margin-left: 26px;
    .hover {
      cursor: pointer;
    }
  }
  .right-in {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ::v-deep .tab-box {
    // position: absolute;
    // right: 0;
    // top: 50%;
    // transform: translateY(-50%);
    background: #81d8d0;
    border-radius: 25px;
    .el-tabs__header {
      padding: 1px 1px 2px 1px;
      margin: 0;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding: 0 30px;
      // margin-top: 30px;
    }
    .is-active {
      color: #73e4dc;
      background: #ffffff;
      text-align: center;
      border-radius: 50px;
      padding: 0 30px;
      text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      font-weight: 600;
    }
    .el-tabs__nav-wrap::after {
      position: static;
    }
    .el-tabs__active-bar {
      background-color: transparent;
    }
  }
  .supplier-box {
    max-width: 680px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .email-box {
      display: flex;
      // justify-content: space-between;
      ::v-deep .code-box {
        .el-form-item__content {
          margin-left: 10px !important;
        }
        .code-btn {
          min-width: 120px;
          border-radius: 6px !important;
          padding: 12px 0;
        }
      }
      ::v-deep .email-input {
        input {
          width: 390px;
        }
      }
    }
    ::v-deep .agreement-box {
      width: 100%;
      text-align: center;
      .el-form-item__content {
        margin-left: 0 !important;
        .el-radio {
          margin-right: 0;
          .el-radio__input.is-checked + .el-radio__label {
            color: #000000;
          }
        }
      }
      .agreement-text {
        color: #81d8d0;
        word-wrap: break-word;
      }
    }
    ::v-deep .regBtn-box {
      text-align: center;
      margin-top: 30px;
      .el-form-item__content {
        margin-left: 0 !important;
      }
      .btn-black {
        font-size: 24px;
        color: #ffffff;
        padding: 12px 60px;
      }
    }
  }
}
</style>
